/** @format */

import { createLogic } from "redux-logic";
import {
  DashboardActions,
  DashboardListSuccess,
  DashboardListFailed,
  planCountSuccess,
  planCountFailed,
} from "../actions";
import { showError } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getDashboardLogic = createLogic({
  type: DashboardActions.REQUEST_DASHBOARD_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DASHBOARD.service,
      ApiRoutes.GET_DASHBOARD.url,
      ApiRoutes.GET_DASHBOARD.method,
      ApiRoutes.GET_DASHBOARD.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(DashboardListFailed());
      done();
    }

    dispatch(
      DashboardListSuccess({
        dashboard: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const getPlanCountLogic = createLogic({
  type: DashboardActions.REQUEST_PLAN_COUNT,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PLAN_COUNT.service,
      ApiRoutes.GET_PLAN_COUNT.url,
      ApiRoutes.GET_PLAN_COUNT.method,
      ApiRoutes.GET_PLAN_COUNT.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(planCountFailed());
      done();
    } else {
      dispatch(
        planCountSuccess({
          totalMonth: responseData.totalMonth,
          totalYear: responseData.totalYear,
        })
      );
      done();
    }
  },
});

/**
 *
 */

export const DashboardLogics = [getDashboardLogic, getPlanCountLogic];
