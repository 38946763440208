/** @format */

export const CmsInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  error: false,
  cmsList: [],
  cmsData: {},
};
