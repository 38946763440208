import { combineReducers } from "redux";

import { AuthReducer } from "./Auth";
import { UserReducer } from "./User";
import { DashboardReducer } from "./Dashboard";
import { NotificationReducer } from "./Notification";
import { GlobalSettingReducer } from "./Setting";
import { LoggerReducer } from "./Logger";
import { ModalInfoReducer } from "./Modal";
import { SubscriptionReducer } from "./Subscription";
import { CmsReducer } from "./Cms";
import { TransactionReducer } from "./Transaction";

export const RootReducer = combineReducers({
  authReducer: AuthReducer,
  userReducer: UserReducer,
  dashboardReducer:DashboardReducer,
  globalSettingReducer: GlobalSettingReducer,
  notificationReducer:NotificationReducer,
  loggerReducer: LoggerReducer,
  modalInfoReducer: ModalInfoReducer,
  subscriptionReducer: SubscriptionReducer,
  cmsReducer: CmsReducer,
  transactionReducer: TransactionReducer,
});
