/** @format */

import { handleActions } from 'redux-actions'
import { UserActions } from '../actions'
import { UserInitialStates } from '../states'

export const UserReducer = handleActions(
  {
    [UserActions.REQUEST_ADD_USER]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: true,
      isAdded: false
    }),

    [UserActions.ADD_USER_SUCCESS]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: true
    }),

    [UserActions.ADD_USER_FAILURE]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      error: true
    }),

    [UserActions.REQUEST_USERS_LIST]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: true
    }),
    [UserActions.USER_LIST_SUCCESS]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: false,
      users: action.payload.users,
      totalRecords: action.payload.totalRecords
    }),
    [UserActions.USER_LIST_FAILURE]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true
    }),

    [UserActions.UPDATE_USER_REQUEST]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: true,
      isAdded: false
    }),
    [UserActions.UPDATE_USER_SUCCESS]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: true
    }),
    [UserActions.UPDATE_USER_FAILURE]: (state = UserInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true
    }),
    [UserActions.VIEW_USER_REQUEST]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: true
    }),
    [UserActions.VIEW_USER_SUCCESS]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: false,
      userData: action.payload.userData
    }),
    [UserActions.VIEW_USER_FAILURE]: (state = UserInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true
    }),

    [UserActions.UPDATE_USER_STATUS_REQUEST]: (
      state = UserInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false
    }),
    [UserActions.UPDATE_USER_STATUS_SUCCESS]: (
      state = UserInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true
    })
  },
  UserInitialStates
)
