/** @format */

import { createLogic } from "redux-logic";
import {
  SubscriptionActions,
  addSubscriptionFailed,
  addSubscriptionSuccess,
  subscriptionListSuccess,
  subscriptionListFailed,
  updateSubscriptionSuccess,
  updateSubscriptionFailed,
  viewSubscriptionSuccess,
  viewSubscriptionFailed,
  requestSubscriptionList,
  updateSubscriptionStatusSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
/**
 *
 */
const addSubscriptionLogic = createLogic({
  type: SubscriptionActions.REQUEST_ADD_SUBSCRIPTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SUBSCRIPTION.service,
      ApiRoutes.ADD_SUBSCRIPTION.url,
      ApiRoutes.ADD_SUBSCRIPTION.method,
      ApiRoutes.ADD_SUBSCRIPTION.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addSubscriptionSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addSubscriptionFailed());
      done();
    }
  },
});

/**
 *
 */
const getSubscriptionsLogic = createLogic({
  type: SubscriptionActions.REQUEST_SUBSCRIPTION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION.service,
      ApiRoutes.GET_SUBSCRIPTION.url,
      ApiRoutes.GET_SUBSCRIPTION.method,
      ApiRoutes.GET_SUBSCRIPTION.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(subscriptionListFailed());
      done();
    }

    dispatch(
      subscriptionListSuccess({
        subscriptions: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */
const updateSubscriptionLogic = createLogic({
  type: SubscriptionActions.UPDATE_SUBSCRIPTION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SUBSCRIPTION.service,
      ApiRoutes.UPDATE_SUBSCRIPTION.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_SUBSCRIPTION.method,
      ApiRoutes.UPDATE_SUBSCRIPTION.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateSubscriptionSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateSubscriptionFailed());
      done();
    }
  },
});

/**
 *
 */
const viewSubscriptionLogic = createLogic({
  type: SubscriptionActions.VIEW_SUBSCRIPTION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_SUBSCRIPTION.service,
      ApiRoutes.VIEW_SUBSCRIPTION.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_SUBSCRIPTION.method,
      ApiRoutes.VIEW_SUBSCRIPTION.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(viewSubscriptionFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewSubscriptionSuccess({
          subscriptionData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const deleteSubscriptionLogic = createLogic({
  type: SubscriptionActions.DELETE_SUBSCRIPTION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SUBSCRIPTION.service,
      ApiRoutes.DELETE_SUBSCRIPTION.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_SUBSCRIPTION.method,
      ApiRoutes.DELETE_SUBSCRIPTION.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(viewSubscriptionFailed(messages[0]));
      done();
    }
    dispatch(requestSubscriptionList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const updateSubscriptionStatusLogic = createLogic({
  type: SubscriptionActions.UPDATE_SUBSCRIPTION_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SUBSCRIPTION_STATUS.service,
      ApiRoutes.UPDATE_SUBSCRIPTION_STATUS.url.replace(
        ":id",
        action.payload.id
      ),
      ApiRoutes.UPDATE_SUBSCRIPTION_STATUS.method,
      ApiRoutes.UPDATE_SUBSCRIPTION_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    );
    if (isError) {
      dispatch(viewSubscriptionFailed(messages[0]));
      done();
    }
    dispatch(
      requestSubscriptionList({
        ...action.payload.query,
      })
    );
    dispatch(updateSubscriptionStatusSuccess());
    showSuccess(messages[0]);
    done();
  },
});

export const SubscriptionLogics = [
  addSubscriptionLogic,
  getSubscriptionsLogic,
  updateSubscriptionLogic,
  viewSubscriptionLogic,
  deleteSubscriptionLogic,
  updateSubscriptionStatusLogic,
];
