/** @format */

import { createAction } from "redux-actions";

export const AuthActions = {
  REQUEST_LOGIN: "Request user login!",
  LOGIN_FAILED: "Login failed",
  LOGOUT_REQUEST: "Request logout",

  GET_USER_DETAILS: "Request to fetch user details",
  USER_DETAILS_SUCCESS: "User details fetched successfully!",
  USER_DETAILS_FAILED: "Failed to fetch User details!",

  CHANGE_PASSWORD_REQUEST: "Request for password change",
  CHANGE_PASSWORD_SUCCESS: "Password updated successfully",
  CHANGE_PASSWORD_FAILED: "Failed to update password",

  SET_PASSWORD_REQUEST: "Request for set password",
  SET_PASSWORD_SUCCESS: "Password set successfully",
  SET_PASSWORD_FAILED: "Failed to set password",

  REQUEST_UPDATE_PROFILE: "Request for update profile details",
  UPDATE_PROFILE_SUCCESS: "Profile updated successfully!",
  UPDATE_PROFILE_FAILED: "Failed to update profile details",
};

export const requestLogin = createAction(AuthActions.REQUEST_LOGIN);
export const loginFailed = createAction(AuthActions.LOGIN_FAILED);
export const requestLogout = createAction(AuthActions.LOGOUT_REQUEST);

export const getUserDetails = createAction(AuthActions.GET_USER_DETAILS);
export const userDetailsSuccess = createAction(
  AuthActions.USER_DETAILS_SUCCESS
);
export const userDetailsFailed = createAction(AuthActions.USER_DETAILS_FAILED);

export const changePasswordReq = createAction(
  AuthActions.CHANGE_PASSWORD_REQUEST
);
export const changePasswordSuccess = createAction(
  AuthActions.CHANGE_PASSWORD_SUCCESS
);
export const changePasswordFailed = createAction(
  AuthActions.CHANGE_PASSWORD_FAILED
);

export const requestUpdateProfile = createAction(
  AuthActions.REQUEST_UPDATE_PROFILE
);
export const updateProfileSuccess = createAction(
  AuthActions.UPDATE_PROFILE_SUCCESS
);
export const updateProfileFailure = createAction(
  AuthActions.UPDATE_PROFILE_FAILED
);
