import { handleActions } from "redux-actions";
import { TransactionActions } from "../actions";
import { TransactionInitialStates } from "../states";

export const TransactionReducer = handleActions(
  {
    [TransactionActions.TRANSACTION_LIST_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [TransactionActions.TRANSACTION_LIST_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      transactions: action.payload.transactions,
      totalRecords: action.payload.totalRecords,
    }),
    [TransactionActions.TRANSACTION_LIST_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [TransactionActions.SUBSCRIPTION_REPORT_LIST_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoadings: true,
    }),
    [TransactionActions.SUBSCRIPTION_REPORT_LIST_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoadings: false,
      subscription: action.payload.subscription,
      totalSubscriptionRecords: action.payload.totalSubscriptionRecords,
    }),
    [TransactionActions.SUBSCRIPTION_REPORT_LIST_FAILED]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isLoadings: false,
      error: true,
    }),

    [TransactionActions.EXPORT_TRANSACTION_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
    }),
    [TransactionActions.EXPORT_TRANSACTION_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
    }),
    [TransactionActions.EXPORT_TRANSACTION_FAILURE]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),

    [TransactionActions.EXPORT_SUBSCRIPTION_REQUEST]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
    }),
    [TransactionActions.EXPORT_SUBSCRIPTION_SUCCESS]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
    }),
    [TransactionActions.EXPORT_SUBSCRIPTION_FAILURE]: (
      state = TransactionInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),
  },
  TransactionInitialStates
);
