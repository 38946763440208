/** @format */

export const UserInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  users: {},
};
