/** @format */

import { createLogic } from "redux-logic";
import {
  CmsActions,
  updateCmsSuccess,
  updateCmsFailed,
  cmsListFailed,
  cmsListSuccess,
  viewCmsFailed,
  viewCmsSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

const getCmsLogic = createLogic({
  type: CmsActions.REQUEST_CMS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CMS.service,
      ApiRoutes.GET_CMS.url,
      ApiRoutes.GET_CMS.method,
      ApiRoutes.GET_CMS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(cmsListFailed());
      done();
    }

    dispatch(
      cmsListSuccess({
        cmsList: responseData.data,
      })
    );
    done();
  },
});

/**
 *
 */
const viewCmsLogic = createLogic({
  type: CmsActions.VIEW_CMS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_CMS.service,
      ApiRoutes.VIEW_CMS.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_CMS.method,
      ApiRoutes.VIEW_CMS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(viewCmsFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewCmsSuccess({
          cmsData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const updateCmsLogic = createLogic({
  type: CmsActions.UPDATE_CMS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CMS.service,
      ApiRoutes.UPDATE_CMS.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_CMS.method,
      ApiRoutes.UPDATE_CMS.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateCmsSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateCmsFailed());
      done();
    }
  },
});

export const CmsLogics = [getCmsLogic, viewCmsLogic, updateCmsLogic];
