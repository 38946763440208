export const AppRoutes = {
  HOME: "/",
  PAGE_NOT_FOUND: "/404",
  PAGE_500: "/500",
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  PROFILE: "/profile",
  NOTIFICATION: "/notification",
  USER: "/users",
  SUBSCRIPTION: "/subscription",
  SETTINGS: "/settings",
  CMS: "/cms",
  EDITCMS: "/cms/:id",
  SUBSCRIPTION_REPORT: "/subscription-report",
  TRANSACTION_REPORT: "/transaction-report",
  LOGGER: "/logger",
};
