import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
// import store from "./store";
import { createBrowserHistory } from "history";
import { AppRoutes } from "./config";
import configureStore from "./redux/store";
import FullPageLoader from "./components/Loader/FullPageLoader";
import "./scss/style.scss";

const history = createBrowserHistory();
const store = configureStore(history);

const loading = (
  <div className="pt-3 text-center">
    <FullPageLoader />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/login"));
const Page404 = React.lazy(() => import("./views/Pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path={AppRoutes.LOGIN}
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              {/* <Route
                exact
                path={AppRoutes.FORGOT_PASSWORD}
                name="Forgot Password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path={AppRoutes.RESET_PASSWORD}
                name="Reset password"
                render={(props) => <ResetPassword {...props} />}
              /> */}

              <Route
                exact
                path={AppRoutes.PAGE_NOT_FOUND}
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path={AppRoutes.PAGE_500}
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path={AppRoutes.HOME}
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

export default App;
