import ApiRoutes from "config/ApiRoutes";
import { createLogic } from "redux-logic";
import { ApiHelper } from "../../helpers/ApiHelper";
import { showError, showSuccess } from "../../helpers/Toast";
import {
  TransactionActions,
  transactionListSuccess,
  transactionListFailed,
  subscriptionReportListFailed,
  subscriptionReportListSuccess,
  exportTransactionSuccess,
  exportTransactionFailed,
  exportSubscriptionSuccess,
  exportSubscriptionFailed
} from "../actions";

const getTransactionLogic = createLogic({
  type: TransactionActions.TRANSACTION_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TRANSACTIONS.service,
      ApiRoutes.GET_TRANSACTIONS.url,
      ApiRoutes.GET_TRANSACTIONS.method,
      ApiRoutes.GET_TRANSACTIONS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(transactionListFailed());
      done();
    } else {
      dispatch(
        transactionListSuccess({
          transactions: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

const getSubscriptionReportLogic = createLogic({
  type: TransactionActions.SUBSCRIPTION_REPORT_LIST_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_REPORT.service,
      ApiRoutes.GET_SUBSCRIPTION_REPORT.url,
      ApiRoutes.GET_SUBSCRIPTION_REPORT.method,
      ApiRoutes.GET_SUBSCRIPTION_REPORT.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(subscriptionReportListFailed());
      done();
    } else {
      dispatch(
        subscriptionReportListSuccess({
          subscription: responseData.data,
          totalSubscriptionRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

 const exportTransactionLogic = createLogic({
  type: TransactionActions.EXPORT_TRANSACTION_REQUEST,
  async process(data, dispatch, done) {
    // eslint-disable-next-line
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_TRANSACTION.service,
      ApiRoutes.EXPORT_TRANSACTION.url,
      ApiRoutes.EXPORT_TRANSACTION.method,
      ApiRoutes.EXPORT_TRANSACTION.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportTransactionFailed(messages[0]));
      done();
    } else {
      dispatch(exportTransactionSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

 const exportSubscriptionLogic = createLogic({
  type: TransactionActions.EXPORT_SUBSCRIPTION_REQUEST,
  async process(data, dispatch, done) {
    // eslint-disable-next-line
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_SUBSCRIPTION.service,
      ApiRoutes.EXPORT_SUBSCRIPTION.url,
      ApiRoutes.EXPORT_SUBSCRIPTION.method,
      ApiRoutes.EXPORT_SUBSCRIPTION.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportSubscriptionFailed(messages[0]));
      done();
    } else {
      dispatch(exportSubscriptionSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});



export const TransactionLogics = [
  getTransactionLogic,
  getSubscriptionReportLogic,
  exportTransactionLogic,
  exportSubscriptionLogic
];
