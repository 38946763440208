/** @format */

import { handleActions } from "redux-actions";
import { SubscriptionActions } from "../actions";
import { SubscriptionInitialStates } from "../states";

export const SubscriptionReducer = handleActions(
  {
    [SubscriptionActions.REQUEST_ADD_SUBSCRIPTION]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),

    [SubscriptionActions.ADD_SUBSCRIPTION_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),

    [SubscriptionActions.ADD_SUBSCRIPTION_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [SubscriptionActions.REQUEST_SUBSCRIPTION_LIST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SubscriptionActions.SUBSCRIPTION_LIST_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      subscriptions: action.payload.subscriptions,
      totalRecords: action.payload.totalRecords,
    }),
    [SubscriptionActions.SUBSCRIPTION_LIST_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SubscriptionActions.UPDATE_SUBSCRIPTION_REQUEST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [SubscriptionActions.UPDATE_SUBSCRIPTION_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [SubscriptionActions.UPDATE_SUBSCRIPTION_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),
    [SubscriptionActions.VIEW_SUBSCRIPTION_REQUEST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SubscriptionActions.VIEW_SUBSCRIPTION_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      subscriptionData: action.payload.subscriptionData,
    }),
    [SubscriptionActions.VIEW_SUBSCRIPTION_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    // [SubscriptionActions.UPDATE_SUBSCRIPTION_STATUS_REQUEST]: (
    //   state = SubscriptionInitialStates,
    //   action
    // ) => ({
    //   ...state,
    //   isLoading: true,
    //   isUpdated: false
    // }),
    // [SubscriptionActions.UPDATE_SUBSCRIPTION_STATUS_SUCCESS]: (
    //   state = SubscriptionInitialStates,
    //   action
    // ) => ({
    //   ...state,
    //   isLoading: false,
    //   isUpdated: true
    // })
  },
  SubscriptionInitialStates
);
