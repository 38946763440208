/** @format */

import { createAction } from "redux-actions";

export const GlobalSettingActions = {
  // get global setting record
  GET_GLOBAL_SETTING_CONTENT: "Get Global Setting Record Request",
  GET_GLOBAL_SETTING_SUCCESS: "Get Global Setting Record successfully",
  GET_GLOBAL_SETTING_FAILED: "Get Global Setting Record failed",

  // update global setting record
  UPDATE_GLOBAL_SETTING_CONTENT: "Update Global Setting Record Request",
  UPDATE_GLOBAL_SETTING_SUCCESS: "Update Global Setting Record successfully",
  UPDATE_GLOBAL_SETTING_FAILED: "Update Global Setting Record failed",

  // update global setting social status
  UPDATE_GLOBAL_SETTING_STATUS_REQUEST:
    "Request to update Global Setting  status",
  UPDATE_GLOBAL_SETTING_STATUS_SUCCESS:
    "Global Setting status updated successfully",
  UPDATE_GLOBAL_SETTING_STATUS_FAILED: "Global Setting status updated failed",
};

// Update status Global Setting  actions
export const requestGlobalSettingContent = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT
);
export const GlobalSettingSuccess = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_SUCCESS
);
export const GlobalSettingFailed = createAction(
  GlobalSettingActions.GET_GLOBAL_SETTING_FAILED
);

// Update status Global Setting actions
export const updateGlobalSettingContent = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_CONTENT
);
export const updateGlobalSettingSuccess = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_SUCCESS
);
export const updateGlobalSettingFailed = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_FAILED
);

// Update status Global Setting actions
export const updateGlobalSettingStatusRequest = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_REQUEST
);
export const updateGlobalSettingStatusSuccess = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_SUCCESS
);
export const updateGlobalSettingStatusFailed = createAction(
  GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_FAILED
);
