/** @format */

import { createAction } from "redux-actions";

export const UserActions = {
  REQUEST_ADD_USER: "Request to add user",
  ADD_USER_SUCCESS: "User added successfully",
  ADD_USER_FAILURE: "Failed to add user",

  REQUEST_USERS_LIST: "Request to fetch users",
  USER_LIST_SUCCESS: "User fetched successfully",
  USER_LIST_FAILURE: "Failed to fetched users",

  UPDATE_USER_REQUEST: "Request to update user",
  UPDATE_USER_SUCCESS: "User updated successfully",
  UPDATE_USER_FAILURE: "Failed to update user",

  VIEW_USER_REQUEST: "Request to view user",
  VIEW_USER_SUCCESS: "User details fetched successfully",
  VIEW_USER_FAILURE: "Failed to fetch user details",

  DELETE_USER_REQUEST: "Request to delete user",

  UPDATE_USER_STATUS_REQUEST: "Request to update user status",
  UPDATE_USER_STATUS_SUCCESS: "User status updated successfully",
};

// Add user actions
export const requestAddUser = createAction(UserActions.REQUEST_ADD_USER);
export const addUserSuccess = createAction(UserActions.ADD_USER_SUCCESS);
export const addUserFailed = createAction(UserActions.ADD_USER_FAILURE);

// List user actions
export const requestUserList = createAction(UserActions.REQUEST_USERS_LIST);
export const userListSuccess = createAction(UserActions.USER_LIST_SUCCESS);
export const userListFailed = createAction(UserActions.USER_LIST_FAILURE);

// Update user actions
export const updateUserRequest = createAction(UserActions.UPDATE_USER_REQUEST);
export const updateUserSuccess = createAction(UserActions.UPDATE_USER_SUCCESS);
export const updateUserFailed = createAction(UserActions.UPDATE_USER_FAILURE);

// View user actions
export const viewUserRequest = createAction(UserActions.VIEW_USER_REQUEST);
export const viewUserSuccess = createAction(UserActions.VIEW_USER_SUCCESS);
export const viewUserFailed = createAction(UserActions.VIEW_USER_FAILURE);

// Delete user actions
export const deleteUserRequest = createAction(UserActions.DELETE_USER_REQUEST);

// Update status user actions
export const updateUserStatusRequest = createAction(
  UserActions.UPDATE_USER_STATUS_REQUEST
);
export const updateUserStatusSuccess = createAction(
  UserActions.UPDATE_USER_STATUS_SUCCESS
);
