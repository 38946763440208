/** @format */

export const NotificationInitialStates = {
  isLoading: false,
  error: false,
  notifications: [],
  allNotifications:{},
  totalRecords: 0,
  unReadRecords: 0,
};
