/** @format */

import { createLogic } from 'redux-logic'
import {
  UserActions,
  addUserFailed,
  addUserSuccess,
  userListSuccess,
  userListFailed,
  updateUserSuccess,
  updateUserFailed,
  viewUserSuccess,
  viewUserFailed,
  requestUserList,
  updateUserStatusSuccess
} from '../actions'
import { showError, showSuccess } from '../../helpers/Toast'
import ApiRoutes from '../../config/ApiRoutes'
import { ApiHelper } from '../../helpers'
/**
 *
 */
const addUserLogic = createLogic({
  type: UserActions.REQUEST_ADD_USER,
  async process (data, dispatch, done) {
    const { action } = data
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_USER.service,
      ApiRoutes.ADD_USER.url,
      ApiRoutes.ADD_USER.method,
      ApiRoutes.ADD_USER.authenticate,
      undefined,
      action.payload
    )
    if (!isError) {
      dispatch(addUserSuccess())
      showSuccess(messages[0])
      done()
    } else {
      showError(messages[0])
      dispatch(addUserFailed())
      done()
    }
  }
})

/**
 *
 */
const getUsersLogic = createLogic({
  type: UserActions.REQUEST_USERS_LIST,
  async process (data, dispatch, done) {
    const { action } = data
    const {
      isError,
      messages,
      data: responseData
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USER.service,
      ApiRoutes.GET_USER.url,
      ApiRoutes.GET_USER.method,
      ApiRoutes.GET_USER.authenticate,
      action.payload,
      undefined
    )
    if (isError) {
      showError(messages[0])
      dispatch(userListFailed())
      done()
    }

    dispatch(
      userListSuccess({
        users: responseData.data,
        totalRecords: responseData.totalRecords
      })
    )
    done()
  }
})

/**
 *
 */
const updateUserLogic = createLogic({
  type: UserActions.UPDATE_USER_REQUEST,
  async process (data, dispatch, done) {
    const { action } = data
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_USER.service,
      ApiRoutes.UPDATE_USER.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_USER.method,
      ApiRoutes.UPDATE_USER.authenticate,
      undefined,
      action.payload.values
    )
    if (!isError) {
      dispatch(updateUserSuccess())
      showSuccess(messages[0])
      done()
    } else {
      showError(messages[0])
      dispatch(updateUserFailed())
      done()
    }
  }
})

/**
 *
 */
const viewUserLogic = createLogic({
  type: UserActions.VIEW_USER_REQUEST,
  async process (data, dispatch, done) {
    const { action } = data
    const {
      isError,
      messages,
      data: responseData
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_USER.service,
      ApiRoutes.VIEW_USER.url.replace(':id', action.payload.id),
      ApiRoutes.VIEW_USER.method,
      ApiRoutes.VIEW_USER.authenticate,
      undefined,
      undefined
    )

    if (isError) {
      dispatch(viewUserFailed(messages[0]))
      done()
    } else {
      dispatch(
        viewUserSuccess({
          userData: responseData.data
        })
      )
      done()
    }
  }
})

/**
 *
 */
const deleteUserLogic = createLogic({
  type: UserActions.DELETE_USER_REQUEST,
  async process (data, dispatch, done) {
    const { action } = data
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_USER.service,
      ApiRoutes.DELETE_USER.url.replace(':id', action.payload.id),
      ApiRoutes.DELETE_USER.method,
      ApiRoutes.DELETE_USER.authenticate,
      undefined,
      undefined
    )
    if (isError) {
      dispatch(viewUserFailed(messages[0]))
      done()
    }
    dispatch(requestUserList())
    dispatch(messages[0])
    showSuccess(messages[0])
    done()
  }
})

/**
 *
 */
const updateUserStatusLogic = createLogic({
  type: UserActions.UPDATE_USER_STATUS_REQUEST,
  async process (data, dispatch, done) {
    const { action } = data
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_USER_STATUS.service,
      ApiRoutes.UPDATE_USER_STATUS.url.replace(':id', action.payload.id),
      ApiRoutes.UPDATE_USER_STATUS.method,
      ApiRoutes.UPDATE_USER_STATUS.authenticate,
      undefined,
      { status: action.payload.status }
    )
    if (isError) {
      dispatch(viewUserFailed(messages[0]))
      done()
    }
    dispatch(
      requestUserList({
        ...action.payload.query
      })
    )
    dispatch(updateUserStatusSuccess())
    showSuccess(messages[0])
    done()
  }
})

export const UserLogics = [
  addUserLogic,
  getUsersLogic,
  updateUserLogic,
  viewUserLogic,
  deleteUserLogic,
  updateUserStatusLogic
]
