/** @format */

import { createLogic } from "redux-logic";
import {
  LoggerActions,
  loggerListSuccess,
  loggerListFailed,
} from "../actions";
import { showError } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
/**
 *
 */

const getLoggerLogic = createLogic({
  type: LoggerActions.REQUEST_LOGGER_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_LOGGER.service,
      ApiRoutes.GET_LOGGER.url,
      ApiRoutes.GET_LOGGER.method,
      ApiRoutes.GET_LOGGER.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(loggerListFailed());
      done();
    }

    dispatch(
        loggerListSuccess({
        logger: responseData.data,
        totalRecords: responseData.totalRecords,
      })
    );
    done();
  },
});

/**
 *
 */


export const LoggerLogics = [
    getLoggerLogic
];
