import { handleActions } from "redux-actions";

import { AuthInitialStates } from "./../states";
import { AuthActions } from "../actions";

export const AuthReducer = handleActions(
  {
    [AuthActions.REQUEST_LOGIN]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [AuthActions.LOGIN_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: false,
      isLoading: false,
    }),

    [AuthActions.GET_USER_DETAILS]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.USER_DETAILS_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      profileUpdating: false,
      isAuthenticated: true,
    }),

    [AuthActions.USER_DETAILS_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.CHANGE_PASSWORD_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: true,
      passwordUpdated: false,
    }),
    [AuthActions.CHANGE_PASSWORD_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
      passwordUpdated: true,
    }),
    [AuthActions.CHANGE_PASSWORD_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
    }),
    [AuthActions.REQUEST_UPDATE_PROFILE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: true,
    }),
    [AuthActions.UPDATE_PROFILE_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: false,
    }),
  },
  AuthInitialStates
);
