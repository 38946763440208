/** @format */

import { createAction } from "redux-actions";

export const LoggerActions = {

  REQUEST_LOGGER_LIST: "Request to fetch Logger",
  LOGGER_LIST_SUCCESS: "Logger fetched successfully",
  LOGGER_LIST_FAILURE: "Failed to fetched Logger",

};

// List logger actions
export const requestLoggerList = createAction(
  LoggerActions.REQUEST_LOGGER_LIST
);
export const loggerListSuccess = createAction(
  LoggerActions.LOGGER_LIST_SUCCESS
);
export const loggerListFailed = createAction(
  LoggerActions.LOGGER_LIST_FAILURE
);

