export const TransactionInitialStates = {
  isLoading: true,
  error: false,
  transactions: [],
  totalRecords: 0,
  subscription: [],
  totalSubscriptionRecords: 0,
  isExported: false,
  isLoadings: true
};
