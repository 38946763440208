/** @format */

import { handleActions } from "redux-actions";
import { LoggerActions } from "../actions";
import { LoggerInitialStates } from "../states";

export const LoggerReducer = handleActions(
  {
    
    [LoggerActions.REQUEST_LOGGER_LIST]: (
      state = LoggerInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [LoggerActions.LOGGER_LIST_SUCCESS]: (
      state = LoggerInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      logger: action.payload.logger,
      totalRecords: action.payload.totalRecords,
    }),
    [LoggerActions.LOGGER_LIST_FAILURE]: (
      state = LoggerInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  LoggerInitialStates
);
