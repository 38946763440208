/** @format */

import { createAction } from "redux-actions";

export * from "./Auth";
export * from "./User";
export * from "./Dashboard";
export * from "./Notification";
export * from "./Setting";
export * from "./Logger";
export * from "./Modal";
export * from "./Subscription";
export * from "./Cms";
export * from "./Transaction";

export const redirectTo = createAction("REDIRECT_TO");
