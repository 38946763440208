import { createLogic } from "redux-logic";

import {
  loginFailed,
  AuthActions,
  userDetailsSuccess,
  redirectTo,
  changePasswordSuccess,
  changePasswordFailed,
  getUserDetails,
  requestLogout,
  userDetailsFailed,
} from "../actions";
import { ApiHelper } from "../../helpers";
import { showSuccess, showError } from "../../helpers";
import ApiRoutes from "../../config/ApiRoutes";
import { AppRoutes } from "../../config";

const loginLogic = createLogic({
  type: AuthActions.REQUEST_LOGIN,
  async process({ action }, dispatch, done) {
    const { email = "", password = "" } = action.payload || {};
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGIN.service,
      ApiRoutes.LOGIN.url,
      ApiRoutes.LOGIN.method,
      ApiRoutes.LOGIN.authenticate,
      undefined,
      { email, password }
    );
    if (!isError) {
      localStorage.setItem("token", responseData.data.token);

      dispatch(userDetailsSuccess({ user: responseData.data }));
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(loginFailed(messages[0]));
      done();
    }
  },
});

const getUserLogic = createLogic({
  type: AuthActions.GET_USER_DETAILS,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined,
      undefined
    );
    if (!isError) {
      dispatch(
        userDetailsSuccess({
          user: responseData.data,
        })
      );
      done();
    } else {
      dispatch(redirectTo({ path: AppRoutes.LOGIN }));
      localStorage.removeItem("token");
      dispatch(userDetailsFailed(messages[0]));
      done();
    }
  },
});

const changePasswordLogic = createLogic({
  type: AuthActions.CHANGE_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const { oldPassword = "", password = "" } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_PASSWORD.service,
      ApiRoutes.CHANGE_PASSWORD.url,
      ApiRoutes.CHANGE_PASSWORD.method,
      ApiRoutes.CHANGE_PASSWORD.authenticate,
      undefined,
      { oldPassword, newPassword: password }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(changePasswordFailed());
      done();
    } else {
      showSuccess("Password updated successfully");
      dispatch(changePasswordSuccess());
      dispatch(requestLogout());
      done();
    }
  },
});

const updateProfileLogic = createLogic({
  type: AuthActions.REQUEST_UPDATE_PROFILE,
  async process({ action }, dispatch, done) {
    const { firstName = "", lastName = "" } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE.service,
      ApiRoutes.UPDATE_PROFILE.url,
      ApiRoutes.UPDATE_PROFILE.method,
      ApiRoutes.UPDATE_PROFILE.authenticate,
      undefined,
      { firstName, lastName }
    );
    if (!isError) {
      showSuccess(messages[0]);
      dispatch(getUserDetails());
      done();
    } else {
      showError(messages[0]);
      done();
    }
  },
});

const logoutLogic = createLogic({
  type: AuthActions.LOGOUT_REQUEST,

  async process({ action }, dispatch, done) {
    localStorage.removeItem("token");
    window.location.reload();
    // dispatch(redirectTo({ path: AppRoutes.LOGIN }));
    done();
  },
});

export const AuthLogics = [
  loginLogic,
  logoutLogic,
  getUserLogic,
  changePasswordLogic,
  updateProfileLogic,
];
