/** @format */

import { createAction } from 'redux-actions';

export const ModelActions = {
  MODAL_TOGGLE_REQUEST: 'Toggle modal Request!',
};

export const modalToggleRequest = createAction(
  ModelActions.MODAL_TOGGLE_REQUEST,
);
