/** @format */

import { createAction } from "redux-actions";

export const DashboardActions = {

  REQUEST_DASHBOARD_LIST: "Request to fetch dashboard",
  DASHBOARD_LIST_SUCCESS: "dashboard fetched successfully",
  DASHBOARD_LIST_FAILURE: "Failed to fetched dashboard",

   REQUEST_PLAN_COUNT: "Request to Plan Users Count",
  PLAN_COUNT_SUCCESS: "Plan Users Count fetched successfully",
  PLAN_COUNT_FAILURE: "Failed to fetched Plan Users Count",

};


// List dashboard actions
export const requestDashboardList = createAction(
  DashboardActions.REQUEST_DASHBOARD_LIST
);
export const DashboardListSuccess = createAction(
  DashboardActions.DASHBOARD_LIST_SUCCESS
);
export const DashboardListFailed = createAction(
  DashboardActions.DASHBOARD_LIST_FAILURE
);

export const requestPlanCount = createAction(
  DashboardActions.REQUEST_PLAN_COUNT
);
export const planCountSuccess = createAction(
  DashboardActions.PLAN_COUNT_SUCCESS
);
export const  planCountFailed = createAction(
  DashboardActions.PLAN_COUNT_FAILURE
);

