const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  GET_PROFILE: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PROFILE: {
    service: "/auth",
    url: "/",
    method: "PUT",
    authenticate: true,
  },
  CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "POST",
    authenticate: true,
  },
  GET_USER: {
    service: "/user",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_USER: {
    service: "/user",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_USER: {
    service: "/user",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_USER: {
    service: "/user",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_USER: {
    service: "/user",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },

  UPDATE_USER_STATUS: {
    service: "/user",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },

  GET_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_GLOBAL_SETTING: {
    service: "/setting",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_GLOBAL_SETTING: {
    service: "/setting",
    url: "/",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_GLOBAL_STATUS: {
    service: "/setting",
    url: "/",
    method: "PATCH",
    authenticate: true,
  },

  UPDATE_SUBSCRIPTION_STATUS: {
    service: "/subscription-plan",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_CMS: {
    service: "/cms",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_CMS: {
    service: "/cms",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CMS: {
    service: "/cms",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_TRANSACTIONS: {
    service: "/subscription-plan",
    url: "/transaction-report",
    method: "GET",
    authenticate: true,
  },
  GET_SUBSCRIPTION_REPORT: {
    service: "/subscription-plan",
    url: "/subscription-report",
    method: "GET",
    authenticate: true,
  },
  GET_LOGGER: {
    service: "/cronjob-log",
    url: "",
    method: "GET",
    authenticate: true,
  },
  EXPORT_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/subscription-export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_TRANSACTION: {
    service: "/subscription-plan",
    url: "/transaction-export",
    method: "GET",
    authenticate: true,
  },
  GET_DASHBOARD: {
    service: "/dashboard",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_PLAN_COUNT:{
    service: "/dashboard",
    url: "/month-year-plan",
    method: "GET",
    authenticate: true,
  },
  GET_NOTIFICATION: {
    service: "/notification",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_NOTIFICATION_STATUS: {
    service: "/notification",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  MARK_ALL_READ_NOTIFICATION_STATUS: {
    service: "/notification",
    url: "/mark-all",
    method: "GET",
    authenticate: true,
  }
};

export default ApiRoutes;
