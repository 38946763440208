import { createAction } from "redux-actions";

export const TransactionActions = {
  TRANSACTION_LIST_REQUEST: "TRANSACTION_LIST_REQUEST!",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAILED: "TRANSACTION_LIST_FAILED",

  SUBSCRIPTION_REPORT_LIST_REQUEST: "SUBSCRIPTION_REPORT_LIST_REQUEST!",
  SUBSCRIPTION_REPORT_LIST_SUCCESS: "SUBSCRIPTION_REPORT_LIST_SUCCESS",
  SUBSCRIPTION_REPORT_LIST_FAILED: "SUBSCRIPTION_REPORT_LIST_FAILED",

  EXPORT_TRANSACTION_REQUEST: "TRANSACTION_EXPORT_REQUEST",
  EXPORT_TRANSACTION_SUCCESS: "TRANSACTION_EXPORT_SUCCESS",
  EXPORT_TRANSACTION_FAILURE: "TRANSACTION_EXPORT_FAILED",

  EXPORT_SUBSCRIPTION_REQUEST: "SUBSCRIPTION_EXPORT_REQUEST",
  EXPORT_SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_EXPORT_SUCCESS",
  EXPORT_SUBSCRIPTION_FAILURE: "SUBSCRIPTION_EXPORT_FAILED",

};

// Transaction listing
export const requestTransactionList = createAction(
  TransactionActions.TRANSACTION_LIST_REQUEST
);
export const transactionListSuccess = createAction(
  TransactionActions.TRANSACTION_LIST_SUCCESS
);
export const transactionListFailed = createAction(
  TransactionActions.TRANSACTION_LIST_FAILED
);

// subscription listing
export const requestSubscriptionReportList = createAction(
  TransactionActions.SUBSCRIPTION_REPORT_LIST_REQUEST
);
export const subscriptionReportListSuccess = createAction(
  TransactionActions.SUBSCRIPTION_REPORT_LIST_SUCCESS
);
export const subscriptionReportListFailed = createAction(
  TransactionActions.SUBSCRIPTION_REPORT_LIST_FAILED
);

// export Transaction

export const exportTransactionRequest = createAction(
  TransactionActions.EXPORT_TRANSACTION_REQUEST
);
export const exportTransactionSuccess = createAction(
  TransactionActions.EXPORT_TRANSACTION_SUCCESS
);
export const exportTransactionFailed = createAction(
  TransactionActions.EXPORT_TRANSACTION_FAILURE
);

// export Subscription

export const exportSubscriptionRequest = createAction(
  TransactionActions.EXPORT_SUBSCRIPTION_REQUEST
);
export const exportSubscriptionSuccess = createAction(
  TransactionActions.EXPORT_SUBSCRIPTION_SUCCESS
);
export const exportSubscriptionFailed = createAction(
  TransactionActions.EXPORT_SUBSCRIPTION_FAILURE
);
