/** @format */

export const SubscriptionInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  subscriptions: {},
};
