/** @format */

import { handleActions } from "redux-actions";
import { DashboardActions } from "../actions";
import { DashboardInitialStates } from "../states";

export const DashboardReducer = handleActions(
  {
    [DashboardActions.REQUEST_DASHBOARD_LIST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      dashboard: {},
    }),
    [DashboardActions.DASHBOARD_LIST_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      dashboard: action.payload.dashboard,
    }),
    [DashboardActions.DASHBOARD_LIST_FAILURE]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [DashboardActions.REQUEST_PLAN_COUNT]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: true,
      totalMonth: [],
      totalYear: [],
    }),
    [DashboardActions.PLAN_COUNT_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: false,
      totalMonth: action.payload.totalMonth,
      totalYear: action.payload.totalYear,
    }),
    [DashboardActions.PLAN_COUNT_FAILURE]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: false,
      error: true,
    }),
  },
  DashboardInitialStates
);
