/** @format */

import { createAction } from "redux-actions";

export const SubscriptionActions = {
  REQUEST_ADD_SUBSCRIPTION: "Request to add subscription",
  ADD_SUBSCRIPTION_SUCCESS: "Subscription added successfully",
  ADD_SUBSCRIPTION_FAILURE: "Failed to add subscription",

  REQUEST_SUBSCRIPTION_LIST: "Request to fetch subscriptions",
  SUBSCRIPTION_LIST_SUCCESS: "Subscription fetched successfully",
  SUBSCRIPTION_LIST_FAILURE: "Failed to fetched subscriptions",

  UPDATE_SUBSCRIPTION_REQUEST: "Request to update subscription",
  UPDATE_SUBSCRIPTION_SUCCESS: "Subscription updated successfully",
  UPDATE_SUBSCRIPTION_FAILURE: "Failed to update subscription",

  VIEW_SUBSCRIPTION_REQUEST: "Request to view subscription",
  VIEW_SUBSCRIPTION_SUCCESS: "Subscription details fetched successfully",
  VIEW_SUBSCRIPTION_FAILURE: "Failed to fetch subscription details",

  DELETE_SUBSCRIPTION_REQUEST: "Request to delete subscription",

  UPDATE_SUBSCRIPTION_STATUS_REQUEST: "Request to update subscription status",
  UPDATE_SUBSCRIPTION_STATUS_SUCCESS:
    "Subscription status updated successfully",
};

// Add subscription actions
export const requestAddSubscription = createAction(
  SubscriptionActions.REQUEST_ADD_SUBSCRIPTION
);
export const addSubscriptionSuccess = createAction(
  SubscriptionActions.ADD_SUBSCRIPTION_SUCCESS
);
export const addSubscriptionFailed = createAction(
  SubscriptionActions.ADD_SUBSCRIPTION_FAILURE
);

// List subscription actions
export const requestSubscriptionList = createAction(
  SubscriptionActions.REQUEST_SUBSCRIPTION_LIST
);
export const subscriptionListSuccess = createAction(
  SubscriptionActions.SUBSCRIPTION_LIST_SUCCESS
);
export const subscriptionListFailed = createAction(
  SubscriptionActions.SUBSCRIPTION_LIST_FAILURE
);

// Update subscription actions
export const updateSubscriptionRequest = createAction(
  SubscriptionActions.UPDATE_SUBSCRIPTION_REQUEST
);
export const updateSubscriptionSuccess = createAction(
  SubscriptionActions.UPDATE_SUBSCRIPTION_SUCCESS
);
export const updateSubscriptionFailed = createAction(
  SubscriptionActions.UPDATE_SUBSCRIPTION_FAILURE
);

// View subscription actions
export const viewSubscriptionRequest = createAction(
  SubscriptionActions.VIEW_SUBSCRIPTION_REQUEST
);
export const viewSubscriptionSuccess = createAction(
  SubscriptionActions.VIEW_SUBSCRIPTION_SUCCESS
);
export const viewSubscriptionFailed = createAction(
  SubscriptionActions.VIEW_SUBSCRIPTION_FAILURE
);

// Delete subscription actions
export const deleteSubscriptionRequest = createAction(
  SubscriptionActions.DELETE_SUBSCRIPTION_REQUEST
);

// Update status subscription actions
export const updateSubscriptionStatusRequest = createAction(
  SubscriptionActions.UPDATE_SUBSCRIPTION_STATUS_REQUEST
);
export const updateSubscriptionStatusSuccess = createAction(
  SubscriptionActions.UPDATE_SUBSCRIPTION_STATUS_SUCCESS
);
