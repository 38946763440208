/** @format */

import { createLogic } from "redux-logic";
import {
  NotificationActions,
  notificationListFailed,
  notificationListSuccess,
  makrAllReadSuccess,
  makrAllReadFailed,
  requestNotificationList
} from "../actions";
import { showError } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
import {
  updateNotificationStatusFailed,
  updateNotificationStatusSuccess,
} from "../actions/Notification";

const getNotificationLogic = createLogic({
  type: NotificationActions.REQUEST_NOTIFICATION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATION.service,
      ApiRoutes.GET_NOTIFICATION.url,
      ApiRoutes.GET_NOTIFICATION.method,
      ApiRoutes.GET_NOTIFICATION.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(notificationListFailed());
      done();
    } else {
      dispatch(
        notificationListSuccess({
          notifications: responseData.data,
          allNotifications: responseData.allData,
          totalRecords: responseData.totalRecords,
          unReadRecords: responseData.unreads,
        })
      );
      done();
    }
  },
});

const updateNotificationStatusLogic = createLogic({
  type: NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_NOTIFICATION_STATUS.service,
      ApiRoutes.UPDATE_NOTIFICATION_STATUS.url.replace(
        ":id",
        action.payload.id
      ),
      ApiRoutes.UPDATE_NOTIFICATION_STATUS.method,
      ApiRoutes.UPDATE_NOTIFICATION_STATUS.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(updateNotificationStatusFailed());
      done();
    }
    dispatch(updateNotificationStatusSuccess({}));
    done();
  },
});

const markAllReadNotificationStatusLogic = createLogic({
  type: NotificationActions.MARK_ALL_READ_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.MARK_ALL_READ_NOTIFICATION_STATUS.service,
      ApiRoutes.MARK_ALL_READ_NOTIFICATION_STATUS.url,
      ApiRoutes.MARK_ALL_READ_NOTIFICATION_STATUS.method,
      ApiRoutes.MARK_ALL_READ_NOTIFICATION_STATUS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(makrAllReadFailed());
      done();
    }
    dispatch(makrAllReadSuccess());
    dispatch(requestNotificationList())
    done();
  },
});

export const NotificationLogics = [
  getNotificationLogic,
  updateNotificationStatusLogic,
  markAllReadNotificationStatusLogic,
];
