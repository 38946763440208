/** @format */

export const GlobalSettingInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  globalSetting: {},
};
