/** @format */
import { createLogic } from "redux-logic";
import { push } from "react-router-redux";

import { AuthLogics } from "./Auth";
import { UserLogics } from "./User";
import { DashboardLogics } from "./Dashboard";
import { NotificationLogics } from "./Notification";
import { GlobalSettingLogics } from "./Setting";
import { LoggerLogics } from "./Logger";
import { SubscriptionLogics } from "./Subscription";
import { CmsLogics } from "./Cms";
import { TransactionLogics } from "./Transaction";

export const redirectToLogic = createLogic({
  type: "REDIRECT_TO",
  async process(data, dispatch, done) {
    const action = data.action;
    dispatch(push(action.payload.path));
    done();
  },
});

export const AllLogics = [
  ...AuthLogics,
  ...UserLogics,
  ...DashboardLogics,
  ...NotificationLogics,
  ...GlobalSettingLogics,
  ...LoggerLogics,
  ...SubscriptionLogics,
  ...CmsLogics,
  ...TransactionLogics,
  redirectToLogic,
];
