/** @format */

import { createAction } from "redux-actions";

export const CmsActions = {
  REQUEST_CMS_LIST: "Request to fetch Cms list",
  CMS_LIST_SUCCESS: "Cms fetched successfully",
  CMS_LIST_FAILURE: "Failed to fetched Cmss",

  UPDATE_CMS_REQUEST: "Request to update Cms",
  UPDATE_CMS_SUCCESS: "Cms updated successfully",
  UPDATE_CMS_FAILURE: "Failed to update Cms",

  VIEW_CMS_REQUEST: "Request to view  Cms",
  VIEW_CMS_SUCCESS: "Cms view  successfully",
  VIEW_CMS_FAILURE: "Failed to view  Cms",
};

// List Cms actions
export const requestCmsList = createAction(CmsActions.REQUEST_CMS_LIST);
export const cmsListSuccess = createAction(CmsActions.CMS_LIST_SUCCESS);
export const cmsListFailed = createAction(CmsActions.CMS_LIST_FAILURE);

// view Cms actions
export const viewCmsRequest = createAction(CmsActions.VIEW_CMS_REQUEST);
export const viewCmsSuccess = createAction(CmsActions.VIEW_CMS_SUCCESS);
export const viewCmsFailed = createAction(CmsActions.VIEW_CMS_FAILURE);

// Update Cms actions
export const updateCmsRequest = createAction(CmsActions.UPDATE_CMS_REQUEST);
export const updateCmsSuccess = createAction(CmsActions.UPDATE_CMS_SUCCESS);
export const updateCmsFailed = createAction(CmsActions.UPDATE_CMS_FAILURE);
