/** @format */

import { handleActions } from "redux-actions";
import { CmsActions } from "../actions";
import { CmsInitialStates } from "../states";

export const CmsReducer = handleActions(
  {
    [CmsActions.REQUEST_CMS_LIST]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [CmsActions.CMS_LIST_SUCCESS]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: false,
      cmsList: action.payload.cmsList,
    }),
    [CmsActions.CMS_LIST_FAILURE]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [CmsActions.UPDATE_CMS_REQUEST]: (state = CmsInitialStates, action) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [CmsActions.UPDATE_CMS_SUCCESS]: (state = CmsInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [CmsActions.UPDATE_CMS_FAILURE]: (state = CmsInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),

    [CmsActions.VIEW_CMS_REQUEST]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [CmsActions.VIEW_CMS_SUCCESS]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: false,
      cmsData: action.payload.cmsData,
    }),
    [CmsActions.VIEW_CMS_FAILURE]: (state = CmsInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  CmsInitialStates
);
