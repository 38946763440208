/** @format */

import { createLogic } from "redux-logic";
import {
  GlobalSettingActions,
  GlobalSettingSuccess,
  GlobalSettingFailed,
  updateGlobalSettingSuccess,
  updateGlobalSettingFailed,
  updateGlobalSettingStatusSuccess,
  updateGlobalSettingStatusFailed,
  requestGlobalSettingContent,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
/**
 *
 */
const globalSettingContentLogic = createLogic({
  type: GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_GLOBAL_SETTING.service,
      ApiRoutes.GET_GLOBAL_SETTING.url,
      ApiRoutes.GET_GLOBAL_SETTING.method,
      ApiRoutes.GET_GLOBAL_SETTING.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        GlobalSettingSuccess({
          globalSetting: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      // showSuccess(messages[0])
      done();
    } else {
      showError(messages[0]);
      dispatch(GlobalSettingFailed());
      done();
    }
  },
});

/**
 *
 */
const updateGlobalSettingSuccessLogic = createLogic({
  type: GlobalSettingActions.UPDATE_GLOBAL_SETTING_CONTENT,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_GLOBAL_SETTING.service,
      ApiRoutes.UPDATE_GLOBAL_SETTING.url,
      ApiRoutes.UPDATE_GLOBAL_SETTING.method,
      ApiRoutes.UPDATE_GLOBAL_SETTING.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateGlobalSettingSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateGlobalSettingFailed());
      done();
    }
  },
});
/**
 *
 */
const updateGlobalSettingStatusLogic = createLogic({
  type: GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_GLOBAL_STATUS.service,
      ApiRoutes.UPDATE_GLOBAL_STATUS.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_GLOBAL_STATUS.method,
      ApiRoutes.UPDATE_GLOBAL_STATUS.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      dispatch(updateGlobalSettingStatusFailed(messages[0]));
      done();
    } else {
      dispatch(
        requestGlobalSettingContent({
          ...action.payload.query,
        })
      );
      dispatch(updateGlobalSettingStatusSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

export const GlobalSettingLogics = [
  globalSettingContentLogic,
  updateGlobalSettingSuccessLogic,
  updateGlobalSettingStatusLogic,
];
