/** @format */

import { handleActions } from "redux-actions";
import { GlobalSettingActions } from "../actions";
import { GlobalSettingInitialStates } from "../states";

export const GlobalSettingReducer = handleActions(
  {
    [GlobalSettingActions.GET_GLOBAL_SETTING_CONTENT]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GlobalSettingActions.GET_GLOBAL_SETTING_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      globalSetting: action.payload.globalSetting,
    }),
    [GlobalSettingActions.GET_GLOBAL_SETTING_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_CONTENT]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),

    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_REQUEST]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),
    [GlobalSettingActions.UPDATE_GLOBAL_SETTING_STATUS_FAILED]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  GlobalSettingInitialStates
);
